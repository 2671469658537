<template>
    <div class="abnormal-warning sn_table_group">
        <sn-search
            class="sn-search search-icon clearfix"
            :snSearch.sync="commonData.snSearch"
            @handleChange="handleChange"
        ></sn-search>
        <div class="content">
            <Mymap :mapData="mapData" class="map" @initmap="initMap"></Mymap>
            <!-- <Dialog
                :dialogData.sync="dialogData"
                @dialogClose="closeDialog"
            ></Dialog> -->
            <sn-dialog :dialogData="dialogDetail">
                <Dialog
                    @closeDialog="closeDialog"
                    @changeTitle="changeTitleDetail"
                    :dialogInfo="dialogDetail.dialogInfo"
                ></Dialog>
            </sn-dialog>
            <div class="legendPart">
                <div class="legendItem">
                    <span>已解除</span
                    ><img src="@image/monitoring_cloud/warn-1.png" />
                </div>
                <div class="legendItem">
                    <span>误报</span
                    ><img src="@image/monitoring_cloud/warn-2.png" />
                </div>
                <div class="legendItem">
                    <span>未解除</span
                    ><img src="@image/monitoring_cloud/warn-0.png" />
                </div>
            </div>
        </div>
        <div id="select-popup" class="scrollbar">
            <ul
                v-for="(value, key) in popupTitle"
                v-if="value.data.length"
                :key="key"
            >
                <h3>{{ key }}</h3>
                <li
                    v-for="(item, index) in value.data"
                    :key="index"
                    @click="getDetail(item)"
                >
                    <span class="index">{{ index + 1 }}.&nbsp;&nbsp;</span>
                    <span class="QKMS">{{
                        item.SJMS && item.SJMS.slice(0, 50)
                    }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import mixins from "./mixins";
import "@libs/leaflet_plug/LeafletMarkercluster/leaflet.markercluster.js";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.css";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.Default.css";
export default {
    name: "abnormalwarningmap",
    mixins: [mixins],
    data() {
        return {
            mapData: {
                mapTypeBtn: true,
                dxtState: true,
                areaState: true,
            },
            popupTitle: {
                未解除: { show: false, data: [] },
                已解除: { show: false, data: [] },
                误报: { show: false, data: [] },
            },
            infoPopup: "",
            infoPopupClick: "",
            basicMap: null,
            markerGroup: null,
        };
    },
    mounted() {
        let buttonData = this.commonData.snSearch.searchBtn.buttonData;
        // buttonData[buttonData.length - 1].operateType = "buttonToList";
        // buttonData[buttonData.length - 1].name = "列表视图";
        if (this.$route.query.from === "taskManage") {
            buttonData[0].operateType = "buttonToTaskList";
            buttonData[0].name = "返回任务管理";
        } else {
            buttonData[0].operateType = "buttonToList";
            buttonData[0].name = "列表视图";
        }

        let popup = document.getElementById("select-popup");

        // eslint-disable-next-line
        this.infoPopup = L.popup({
            closeOnClick: true,
            closeButton: false,
            offset: [0, -25],
            minWidth: 12,
            className: "aaa",
        })
            .setContent(popup)
            .setLatLng([0, 0]);
        // eslint-disable-next-line
        this.infoPopupClick = L.popup({
            closeOnClick: true,
            closeButton: false,
            offset: [0, -25],
            minWidth: 12,
            className: "aaa",
        })
            .setContent(popup)
            .setLatLng([0, 0]);
        this.initCluster();
        if (this.$route.params) {
            this.markerData = this.$route.params;
            this.toMarkerData();
        }
    },
    methods: {
        ...mapActions([
            "getRcxcycjlMapList", // 异常预警列表(地图)
        ]),
        handleChange(type, value) {
            switch (type) {
                case "buttonToList": // 地图视图
                    this.toPage([{ path: "abnormalWarning" }]);
                    break;
                case "treesearch":
                    if (value[1] === "YCLX") {
                        // 异常类型
                        this.tasktype = value[0];
                    }
                    if (value[1] === "SBRY") {
                        // 上报人
                        this.Gatherer = value[0];
                    }
                    this.getRecords();
                    break;
                case "search":
                    if (value[1] === "keyword") {
                        // 关键字
                        this.keyword = value[0];
                    } else if (value[1] === "YCLX") {
                        // // 异常类型
                        // this.tasktype = value[0];
                    } else if (value[1] === "SBRY") {
                        // 上报人
                        this.Gatherer = value[0];
                    } else if (value[1] === "YJZT") {
                        // 预警状态
                        this.status = value[0];
                    } else if (value[1] === "YJSJ") {
                        this.time = value[0];
                    }
                    this.getRecords();
                    break;
                case "buttonToTaskList":
                    this.$router.push({
                        name: "taskManage",
                    });
                    break;
            }
        },
        async getRecords() {
            let result = await this.getRcxcycjlMapList({
                keyword: this.keyword,
                tasktype: this.tasktype,
                Gatherer: this.Gatherer,
                time: this.time,
                status: this.status,
            });
            this.mapPoint = result;
            this.initMapPoint();
        },
        initMap(map) {
            this.basicMap = map;
        },
        initCluster() {
            // 初始化聚合图层
            let _this = this;
            let map = this.basicMap;
            let selectID = this.selectID;
            // eslint-disable-next-line
            let markers = L.markerClusterGroup({
                maxClusterRadius: 20,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: function (cluster) {
                    console.log(cluster);
                    let da = cluster.getAllChildMarkers();
                    console.log(da);
                    let warn = 1;
                    da.forEach(function (element, index) {
                        if (element.data.YJSFJC === 0) {
                            warn = 0;
                        }
                    });
                    let css = warn
                        ? "markerSelected"
                        : "css_animation markerSelected";
                    let html1 = `<div class='${css}' style='background-image:url("static/img/legend/JCZT/warn${warn}.png")'></div>`;
                    let html2 =
                        "<div class='icon-number'>" +
                        cluster.getChildCount() +
                        "</div>";
                    let options = {
                        html: "<div>" + html1 + html2 + "</div>",
                        iconAnchor: [8, 19] || [15, 34],
                        selfType: "cluster",
                    };

                    for (let i = 0; i < da.length; i++) {
                        if (selectID === da[i].data.ID) {
                            options.className = "selectedAnimation";
                            break;
                        }
                    }
                    console.log(options);
                    // eslint-disable-next-line
                    return L.divIcon(options);
                },
            });
            _this.markers = markers;
            markers
                .on("clustermouseover", function (a) {
                    let da = a.layer.getAllChildMarkers();
                    _this.clearData();
                    da.forEach(function (element, index) {
                        let d = element.data;
                        let JCLX = d.YJSFJC === 0 ? "未解除" : "已解除";
                        _this.popupTitle[JCLX].data.push(d);
                    });
                    _this.infoPopup
                        .setLatLng([Number(a.latlng.lat), Number(a.latlng.lng)])
                        .openOn(map);
                    if (_this.clickLayer !== a.layer) {
                        _this.clickLayer = "";
                    }
                })
                .on("clustermouseout", function (a) {
                    if (a.layer !== _this.clickLayer) {
                        map.closePopup(_this.infoPopup);
                    }
                })
                .on("clusterclick", function (a) {
                    let da = a.layer.getAllChildMarkers();
                    _this.clickLayer = a.layer;
                    _this.clearData();
                    da.forEach(function (element, index) {
                        let d = element.data;
                        let JCLX = d.YJSFJC === 0 ? "未解除" : "已解除";
                        _this.popupTitle[JCLX].data.push(d);
                    });
                    _this.infoPopup
                        .setLatLng([Number(a.latlng.lat), Number(a.latlng.lng)])
                        .openOn(map);
                    _this.getClusterSelect(a.layer);
                });
            map.addLayer(markers);
        },
        async initMapPoint() {
            let map = this.basicMap;
            map.closePopup();
            if (this.markerGroup) {
                this.markerGroup.clearLayers();
            }
            // eslint-disable-next-line
            this.markerGroup = L.layerGroup([]);
            this.markerGroup.addTo(map);
            // let markers = [];
            console.log(this.mapPoint);
            let minJD = "";
            let minWD = "";
            let maxJD = "";
            let maxWD = "";
            this.mapPoint.forEach((item, i) => {
                if (item.JD && item.WD) {
                    if (i === 0) {
                        minJD = maxJD = item.JD;
                        minWD = maxWD = item.WD;
                    }
                    minJD = item.JD < minJD ? item.JD : minJD;
                    minWD = item.WD < minWD ? item.WD : minWD;
                    maxJD = item.JD > maxJD ? item.JD : maxJD;
                    maxWD = item.WD > maxWD ? item.WD : maxWD;
                    let css =
                        item.YCSFJC === "1" || item.YCSFJC === "2"
                            ? "markerSelected"
                            : "css_animation markerSelected";
                    let url = require(`@image/monitoring_cloud/warn${item.YCSFJC}.png`);
                    let options = {
                        className: "",
                        html: `<div class="${css}" style='background-image:url(${url})'></div>`,
                        iconAnchor: [15, 34],
                        popupAnchor: [0, -15],
                        bgPos: [0, 0],
                    };
                    // if (this.selectID === item.YCYSBM) {
                    //     options.className = 'selectedAnimation';
                    // }
                    // eslint-disable-next-line
                    let icon = L.divIcon(options);
                    // eslint-disable-next-line
                    let marker = L.marker([item.WD, item.JD], {
                        icon: icon,
                    });
                    this.markerGroup.addLayer(marker);
                    marker.data = item;
                    marker
                        .on("mouseover", (e) => {
                            let da = e.target.data.ycjllist || [item];
                            this.clearData();
                            da.length > 0 &&
                                da.forEach((element, index) => {
                                    let d = element;
                                    let JCLX =
                                        d.YCSFJC === "1"
                                            ? "已解除"
                                            : d.YCSFJC === "2"
                                            ? "误报"
                                            : "未解除";
                                    this.popupTitle[JCLX].data.push(d);
                                });
                            map.closePopup();
                            this.infoPopup
                                .setLatLng([Number(item.WD), Number(item.JD)])
                                .openOn(map);
                        })
                        .on("mouseout", (e) => {
                            map.closePopup(this.infoPopup);
                            if (e.layer !== this.clickLayer) {
                                map.closePopup(this.infoPopupClick);
                            }
                        })
                        .on("click", (e) => {
                            let da = e.target.data.ycjllist || [item];
                            this.clickLayer = e.layer;
                            this.clearData();
                            da.length > 0 &&
                                da.forEach((element, index) => {
                                    let d = element;
                                    let JCLX =
                                        d.YCSFJC === "1"
                                            ? "已解除"
                                            : d.YCSFJC === "2"
                                            ? "误报"
                                            : "未解除";
                                    this.popupTitle[JCLX].data.push(d);
                                });
                            map.closePopup(this.infoPopup);
                            this.infoPopupClick
                                .setLatLng([Number(item.WD), Number(item.JD)])
                                .openOn(map);
                        });
                    this.markerGroup.addLayer(marker);
                    if (item.YCJLLIST && item.YCJLLIST.length) {
                        // eslint-disable-next-line
                        let divIcon = L.divIcon({
                            className: "my-div-icon",
                            html:
                                `<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">` +
                                item.YCJLLIST.length +
                                "</div>",
                            iconAnchor: [-3, 38],
                        });
                        // eslint-disable-next-line
                        let numMarker = L.marker([item.WD, item.JD], {
                            icon: divIcon,
                        });
                        this.markerGroup.addLayer(numMarker);
                    }
                    if (
                        this.$route &&
                        this.$route.params &&
                        this.$route.params.data &&
                        this.$route.params.data.jcdxid &&
                        this.$route.params.data.jcdxid === item.ID
                    ) {
                        this.basicMap.setView([item.WD, item.JD], 17);

                        let da = marker.data.ycjllist || [item];
                        this.clearData();
                        da.length > 0 &&
                            da.forEach((element, index) => {
                                let d = element;
                                let JCLX =
                                    d.YCSFJC === "1"
                                        ? "已解除"
                                        : d.YCSFJC === "2"
                                        ? "误报"
                                        : "未解除";
                                this.popupTitle[JCLX].data.push(d);
                            });
                        map.closePopup(this.infoPopup);
                        this.infoPopupClick
                            .setLatLng([Number(item.WD), Number(item.JD)])
                            .openOn(map);
                    }
                }
            });
            // this.basicMap.setMinZoom(8).fitBounds([
            //     [minWD, minJD],
            //     [maxWD, maxJD],
            // ]);
        },
        getDetail(item) {
            $(".leaflet-marker-icon").removeClass("selectedAnimation");
            // this.dialogDetail = {
            //     dialog: true,
            //     tableData: item,
            //     title: "异常预警",
            //     class: "deepBlueDialog",
            // };
            this.dialogDetail.dialog = true;
            this.dialogDetail.dialogInfo = item;
        },
        clearData() {
            // 将弹出框数据清空初始化
            let _this = this;
            Object.keys(_this.popupTitle).forEach((key) => {
                _this.popupTitle[key].data = [];
            });
        },
        toMarkerData() {
            // 列表页面跳转地图，只显示当前点
            let WD;
            let JD;
            let val = this.markerData;
            console.log(val);
            if (this.markerData) {
                WD = this.markerData.WD;
                JD = this.markerData.JD;
                this.selectID = this.markerData.ID;
            }
            if (WD && JD) {
                this.basicMap.setView([WD, JD], 17);
            }
        },
        // 详情 转预警 title
        changeTitleDetail(title) {
            this.dialogDetail.title = title;
        },
    },
};
</script>
<style lang="scss" scoped>
.abnormal-warning {
    .content {
        width: 100%;
        height: calc(100% - 60px);
        position: relative;
        margin-top: 10px;
    }
}

#select-popup {
    max-height: 200px;
    width: 250px;
    overflow-x: hidden;
    overflow-y: auto;
}

#select-popup h3 {
    padding: 5px 0;
    text-align: center;
    font-size: 12px;
    color: #48576a;
}

#select-popup li {
    line-height: 25px;
    border-bottom: 1px #eee dashed;
    text-indent: -1rem;
    padding-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 15px;
    font-size: 12px;
    color: #48576a;
    cursor: pointer;
}

#select-popup li:hover {
    color: #409eff;
}
.legendPart {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 120px;
    height: 100px;
    z-index: 1000;
    background: #ffffff;
    .legendItem {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin: 2px 5px;
        display: flex;
        align-items: center;
        span {
            margin: 0px 10px;
        }
    }
}
</style>